<template>
    <div>
      <v-container>
        <h2 class="text-h5 mb-4">App Updates</h2>
        <v-card class="mb-4" style="padding: 20px">
          <v-row>
            <v-col md="3">
              <v-text-field
                v-model="filters.search_keyword"
                label="Search Keyword"
                outlined
                @change="fetchUpdates()"
                dense
                placeholder="Search Keyword"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col md="3">
              <v-select
                style="margin-right: 10px"
                v-model="filters.status"
                dense
                :items="[{name : 'All' , value : ''}, { name : 'Active', value : 1}, {name : 'In-Active' , value : 0}]"
                item-text="name"
                item-value="value"
                @change="fetchUpdates()"
                label="Status"
                outlined
              >
              </v-select>
            </v-col>
            <v-col md="6" style="text-align: right;">
              <v-btn
                color="primary"
                @click="openCreateDialog"
                class="tet-white mr-2"
                small
              >Create New Update</v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-data-table :headers="headers" :items="updates.data" class="elevation-1" disable-pagination hide-default-footer>
          <template v-slot:body="{ items }">
            <tbody v-if="!loading_data">
              <tr v-for="item in items" :key="item.id">
                <td>{{ item.en_message }}</td>
                <td>{{ item.en_badge_text }}</td>
                <td>{{ item.link_url ? (item.link_url.length > 20 ? item.link_url.substring(0,20) + '...' : item.link_url) : '' }}</td>
                <td>
                  <v-chip small class="ma-2" color="green" text-color="white" v-if="item.status == '1'">Active</v-chip>
                  <v-chip small class="ma-2" color="red" text-color="white" v-else>Inactive</v-chip>
                </td>
                <td>
                  <v-btn fab small color="primary darken-1" class="table-action--button" @click="openEditDialog(item.id)">
                    <v-icon>{{ mdiSquareEditOutline }}</v-icon>
                  </v-btn>
                  <v-btn fab small color="primary darken-1" class="table-action--button" @click="openDeleteDialog(item.id)">
                    <v-icon>{{ mdiTrashCanOutline }}</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="4" class="text-center" style="padding: 80px 0px">
                  <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <v-pagination
          v-model="updates.current_page"
          class="my-4"
          @input="fetchUpdates()"
          :length="updates.last_page"
          :total-visible="7"
        ></v-pagination>

      </v-container>
  
      <!-- Create/Edit Dialog -->
      <v-dialog v-model="create_update_dialog" width="1000" :retain-focus="false">
        <v-card>
          <v-card-title class="text-h6 grey lighten-2">
            {{ edit_mode ? 'Edit App Update' : 'Create New App Update' }}
          </v-card-title>
  
          <v-card-text>
            <div style="padding: 20px">
              <v-select
                v-model="update_data.status"
                dense
                :items="content_status"
                label="Status"
                item-text="name"
                item-value="value"
                outlined
              ></v-select>

              <v-text-field
                v-model="update_data.link_url"
                label="Link URL (Optional)"
                outlined
                placeholder="https://example.com"
                class="mt-4"
              ></v-text-field>
            </div>
  
            <!-- Language Tabs -->
            <v-tabs v-model="activeTab" background-color="primary" dark>
              <v-tab v-for="(lang, index) in languages" :key="index">{{ lang.label }}</v-tab>
            </v-tabs>
  
            <v-tabs-items v-model="activeTab">
              <v-tab-item v-for="(lang, index) in languages" :key="index">
                <v-card flat>
                  <div style="padding: 20px">
                    <v-text-field
                      v-model="update_data[`${lang.prefix}_message`]"
                      :label="`${lang.label} Update Message`"
                      outlined
                    ></v-text-field>
                    <v-text-field
                      v-model="update_data[`${lang.prefix}_badge_text`]"
                      :label="`${lang.label} Badge Text`"
                      outlined
                    ></v-text-field>
                    <v-text-field
                      v-model="update_data[`${lang.prefix}_link_text`]"
                      :label="`${lang.label} Link Text (only if link is provided)`"
                      outlined
                    ></v-text-field>
                  </div>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="create_update_dialog = false">Cancel</v-btn>
            <v-btn
              color="primary"
              text
              @click="saveUpdate"
              :loading="is_loading"
              :disabled="is_loading"
            >
              <v-progress-circular
                v-if="is_loading"
                indeterminate
                color="primary"
                size="20"
              ></v-progress-circular>
              <span v-else>Save</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <!-- Delete Dialog -->
      <v-dialog v-model="delete_dialog" width="500">
        <v-card>
          <v-card-title class="text-h6 grey lighten-2">Delete App Update</v-card-title>
          <v-card-text>Are you sure you want to delete this update?</v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="delete_dialog = false">Cancel</v-btn>
            <v-btn
              color="primary"
              text
              @click="deleteUpdate"
              :loading="is_loading"
              :disabled="is_loading"
            >Confirm Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js'
  
  export default {
    data() {
      return {
        activeTab: 0,
        languages: [
          { label: 'English', prefix: 'en' },
          { label: 'Spanish', prefix: 'es' },
          { label: 'German', prefix: 'de' },
          { label: 'French', prefix: 'fr' },
          { label: 'Italian', prefix: 'it' },
          { label: 'Dutch', prefix: 'nl' },
        ],
        loading_data: false,
        is_loading: false,
        edit_mode: false,
        filters: {
          search_keyword: '',
          status: '',
        },
        headers: [
          { text: 'Message', value: 'en_message' },
          { text: 'Badge Text', value: 'en_badge_text' },
          { text: 'Link URL', value: 'link_url' },
          { text: 'Status', value: 'status' },
          { text: 'Actions', value: 'actions' },
        ],
        updates: {
          current_page: 1,
          data: [],
        },
        update_data: {
          status: 1,
          id: null,
          link_url: '',
        },
        create_update_dialog: false,
        delete_dialog: false,
        content_status: [
          {name: 'Active', value: 1}, 
          {name: 'In-Active', value: 0}
        ],
        mdiSquareEditOutline,
        mdiTrashCanOutline,
      }
    },
  
    created() {
      // Initialize language-specific fields
      this.languages.forEach(lang => {
        this.update_data[`${lang.prefix}_message`] = ''
        this.update_data[`${lang.prefix}_badge_text`] = ''
        this.update_data[`${lang.prefix}_link_text`] = ''
      })
      this.fetchUpdates()
    },
  
    methods: {
      async fetchUpdates() {
        this.loading_data = true
        try {
          const response = await axios.get('admin/app-updates', {
            params: {
              page: this.updates.current_page,
              search: this.filters.search_keyword,
              status: this.filters.status,
            },
          })
          this.updates = response.data?.data
        } catch (error) {
          console.error('Error fetching updates:', error)
        }
        this.loading_data = false
      },
  
      async saveUpdate() {
        this.is_loading = true
        try {
          if (this.edit_mode) {
            await axios.put(`admin/app-updates/${this.update_data.id}`, this.update_data)
          } else {
            await axios.post('admin/app-updates', this.update_data)
          }
          this.create_update_dialog = false
          this.fetchUpdates()
        } catch (error) {
          console.error('Error saving update:', error)
        }
        this.is_loading = false
      },
  
      openEditDialog(id) {
        this.edit_mode = true
        const update = this.updates.data.find(item => item.id === id)
        this.update_data = { 
          ...update,
          status: Number(update.status)
        }
        this.create_update_dialog = true
      },
  
      openDeleteDialog(id) {
        this.update_data.id = id
        this.delete_dialog = true
      },
  
      async deleteUpdate() {
        this.is_loading = true
        try {
          await axios.delete(`admin/app-updates/${this.update_data.id}`)
          this.delete_dialog = false
          this.fetchUpdates()
        } catch (error) {
          console.error('Error deleting update:', error)
        }
        this.is_loading = false
      },
  
      resetForm() {
        this.update_data = {
          status: 1,
          id: null,
          link_url: '',
        }
        // Reset language-specific fields
        this.languages.forEach(lang => {
          this.update_data[`${lang.prefix}_message`] = ''
          this.update_data[`${lang.prefix}_badge_text`] = ''
          this.update_data[`${lang.prefix}_link_text`] = ''
        })
        this.activeTab = 0 // Reset to first tab
      },
  
      openCreateDialog() {
        this.edit_mode = false
        this.resetForm()
        this.create_update_dialog = true
      },
    },
  }
  </script>
  